import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { locationService } from '@grafana/runtime';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    textBox: css({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'auto',
      fontSize: '24px',
      fontWeight: 500,
      height: '100%',
      minHeight: '60px'
    }),
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  const styles = useStyles2(getStyles);
  const [finalMacAddress, setFinalMacAddress] = React.useState('');

  React.useEffect(() => {
    let scanned = '';
    const getMacAddress = (fullString: string) => {
      scanned = '';
      if (!fullString) {
        console.log('no string to split');
        return;
      }
      let splittedMacAddress = fullString.split('__');
      if (splittedMacAddress[1].length !== 16) {
        console.log('mac address format invalid !!');
        return;
      }
      const formatted = splittedMacAddress[1].replace(/(.{2})(?=.)/g, '$1-');
      locationService.partial({ 'var-mac_address': formatted }, true);
      setFinalMacAddress(formatted);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        return;
      }

      if (event.key === "Enter") {
        getMacAddress(scanned);
        return;
      }
      scanned += event.key;
    };

    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



  return (
    <div className={styles.textBox} style={{ height: `${height}px` }}>
      {finalMacAddress}
    </div>
  );
};
